/* filter bg */
.filter {
    position: absolute;
    top: 71px;
    background-color: #fafafa;
    width: calc(100% - 32px);
    padding: 16px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.filter-margin {
    margin-bottom: 15px;
}

/* filter content */
.icon-style {
    font-size: 15px !important;
}
.select, .MuiInputBase-formControl {
    border-radius: 0 !important;
}
.autocomplete>.MuiFormControl-root>.MuiInputBase-root {
    border-radius: 0 !important;
    height: 40px !important;
}
.MuiInputBase-colorPrimary {
    background-color: #fff;
    height: 40px !important;
    /* border-radius: 20px !important; */
}
.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
    max-height: calc(25% - 32px);
}
.hide-no-option .MuiAutocomplete-noOptions {
    display: none;
}
.dropdown-icon-group {
    display: flex;
    flex-direction: column;
}
svg[data-testid="ArrowDropDownIcon"] {
    position: relative;
    top: -5px;
}
svg[data-testid="ArrowDropUpIcon"] {
    position: relative;
    top: 5px;
}

/* filter submit btns */
.filter-margin .submit {
    background-color: #0BADC5;
    border-radius: 25px;
    padding: 0.5rem 1.5rem;
    text-transform: capitalize;
    font-size: 13px !important;
    box-shadow: none !important;
}
.filter-margin .submit:hover {
    background-color: #0BADC5;
}
.filter-margin .clear-link {
    background-color: transparent;
    color: #333;
    border-radius: 25px;
    padding: 0.5rem 1.5rem;
    text-transform: capitalize;
    font-size: 13px !important;
    box-shadow: none !important;
    margin-right: 10px;
}

.custom-autocomplete .test {
    flex-direction: row;
    vertical-align: middle;
}

.custom-autocomplete .MuiFormControl-root .MuiInputBase-root {
    padding: 0 0 0 10px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
}

.custom-autocomplete .MuiFormControl-root .MuiInputBase-root input { 
    min-width: 35px;
}

.custom-autocomplete .MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment button.MuiButtonBase-root {
    top: 2px;
}
 
.auto-complete-selected-container {
    display: flex; 
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 0.8rem;
} 

.MuiAutocomplete-option {
    font-size: 0.8rem !important;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.align-items-end {
    align-items: flex-end;
}