html {
  overflow: hidden;
}

a {
  color: #0BADC5;
}

.blue-color {
  color: #0BADC5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.MuiIconButton-root svg.MuiSvgIcon-root,
svg.MuiSvgIcon-root {
  font-size: 1rem;
}

/* Header Navigation */
header.MuiPaper-root {
  background: #fff;
  box-shadow: none;
}

header.MuiPaper-root .MuiToolbar-regular {
  padding: 0;
}

/* Layout */
.layout {
  height: calc(100vh - 140px);
  overflow-y: auto;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

/* Footer Navigation */
.MuiTabs-flexContainer,
.MuiTabs-flexContainer a.MuiTab-root,
.MuiTabs-root .MuiTabScrollButton-root {
  background: #1F2532;
  color: #fff;
  opacity: 1;
}

.MuiTabs-root .MuiTabScrollButton-root.Mui-disabled {
  background-color: #1f2532;
  opacity: 0.9;
}

.MuiTabs-flexContainer a.MuiTab-root {
  height: 70px;
  width: 25%;
  color: #939393;
  max-width: initial;
}

.MuiTabs-scroller .MuiTabs-indicator {
  height: 4px;
  background-color: #D0434E;
  border-radius: 2px;
}

.MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: #fff;
}

#loading-icon {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 0;
  margin: 0;
}

.barcode-img {
  background: url('/public/barcode-scan.gif') no-repeat;
  width: 220px;
  height: 220px;
  position: absolute;
  bottom: calc(50% - 110px);
  left: 50%;
  display: inline-block;
  transform: translateX(-50%);
}

/* override input label */
fieldset>legend {
  width: 0 !important;
}

label {
  font-size: 1.0rem !important;
  line-height: 7px !important;
  position: relative !important;
  display: block !important;
  left: -15px !important;
  overflow: initial !important;
  margin-top: 16px;
  font-weight: 400 !important;
  color: #000000e8 !important;
}

.font-override>span {
  font-size: 13px !important;
  padding: 4px;
}

.font-override>span.MuiTypography-root {
  font-size: 13px !important;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiSelect-select {
  max-width: calc(100% - 45px) !important;
  position: absolute;
  z-index: 1;
}

.dropdown-icon-group {
  position: absolute;
  right: 10px;
  z-index: 0;
}

.MuiAutocomplete-root {
  cursor: pointer;
}

.MuiAutocomplete-endAdornment .dropdown-icon-group {
  position: inherit;
  right: 0;
}

.form-error {
  color: #d32f2f;
  font-size: 0.8rem;
  text-transform: none;
}

.back a {
  color: #A4ACB3 !important;
}

.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #424242 !important; 
}

/* assignment */
.assignment .MuiDialog-scrollPaper .MuiDialog-paper,
.assignment .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogContent-dividers {
  overflow-y: visible !important;
}

.MuiDialogContent-root.MuiDialogContent-dividers.assignment-area {
  background-color: #fafafa;
}

.MuiDialogContent-root.MuiDialogContent-dividers.assignment-area .MuiGrid-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
}

.assignment-area button.assign {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.assignment-area button.disable, .clear-btn {
  background-color: #E1E1E1 !important; 
  color: #707070 !important;
  cursor: pointer !important;
}

.assignment-area button.assign,
.assignment-area button.disable, .clear-btn {
  margin-bottom: 0;
  margin-bottom: 0 !important;
  box-shadow: none !important;
  font-weight: 400 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  min-width: 85px !important;
}
.assignment button.disable-btn,
.assignment-area button.disable-btn {
  cursor: not-allowed !important; 
}

button.loading.MuiButtonBase-root {
  min-width: 105px !important;
}

.loading .MuiLoadingButton-loadingIndicator {
 left: 15px !important;
}

.justify-content-popup {
  text-align: center;
  justify-content: center;
}

.font {
  font-size: 0.9rem;
}

/* 7136 fix */
.MuiTablePagination-input {
  width: 60px;
  margin-right: 10px;
}

.MuiTablePagination-input .MuiSvgIcon-fontSizeMedium {
    position: relative;
    top: 0;
    right: -38px;
}

.MuiAutocomplete-listbox li {
  padding: 7.5px 20px 7.5px; 
  font-size: 0.9rem;
  cursor: pointer;
}

.MuiAutocomplete-listbox li.terminal:hover, .MuiAutocomplete-listbox li.region:hover {
  color: #164b70;  
  font-weight: 600;
}

.MuiAutocomplete-listbox li.region {
  position: relative;
  font-weight: bold;
  font-style: italic;
}

.MuiAutocomplete-listbox li.terminal:first-child {
  margin: 0;
}

.MuiAutocomplete-listbox li.terminal:nth-child(1){
  font-weight: bold;
  font-style: italic;
}

.MuiAutocomplete-listbox li.terminal:first-child:before, .MuiAutocomplete-listbox li.terminal:first-child:after {
   background: transparent;
}

.MuiAutocomplete-listbox li:nth-child(2):before {
  background: transparent !important;
}

.MuiAutocomplete-listbox li.region:before {
    content: '';
    width: 10px;
    height: 18px;
    background: #fff;
    display: block;
    position: absolute;
    top: -16px;
    left: 25px;
}

.MuiAutocomplete-listbox li.terminal {
  margin-left: 25px;
  position: relative;
}

.MuiAutocomplete-listbox li.terminal:before { 
  content: '';
  width: 1px;
  height: 38px;
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  background: #ccc;
}

.MuiAutocomplete-listbox li.terminal:after { 
  content: '';
  width: 9px;
  height: 1px;
  display: block;
  position: absolute;
  top: 17px;
  left: 5px;
  background: #ccc;
}




.li {
  height: 50px;
  width: 100px;
  padding-left: 20px;
  background: #F5FBF1;
  display: inline-block;
  position: relative;
  margin-left: 20px;
  line-height: 50px;
  font-family: sans-serif;
  font-size: 15px;
}
.li:before, .li:after {
  content: '';
  left: -15px;
  position: absolute;
  height: 23px;
  width: 132px;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
.li:before {
  border-top: 2px solid black;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: skewX(30deg);
  -moz-transform: skewX(30deg);
  -ms-transform: skewX(30deg);
  transform: skewX(30deg);
  top: 0;
  box-shadow: inset 0 8px 0 8px #F5FBF1, inset -6px 8px 0 8px #F5FBF1;
}
.li:after {
  border-bottom: 2px solid black;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  transform: skewX(-30deg);
  bottom: 0;
  box-shadow: inset 0 -8px 0 8px #F5FBF1, inset -6px -8px 0 8px #F5FBF1;
}
.li:hover {
  background: #C0EBA4;
}
.li:hover:before { box-shadow: inset 0 8px 0 8px #C0EBA4, inset -6px 8px 0 8px #C0EBA4;}
.li:hover:after { box-shadow: inset 0 -8px 0 8px #C0EBA4, inset -6px -8px 0 8px #C0EBA4;}

/*First and Last styles*/
.li:first-of-type {
  left: -15px;
  box-shadow: 15px 0 0 0 #F5FBF1;
  border-left: 2px solid black;
}
.li:first-of-type:before, .li:first-of-type:after {
  left: -1px;
  width: 135px;
  border-left: 0;
}
.li:first-of-type:hover {box-shadow: 15px 0 0 0 #C0EBA4;}
.li:last-of-type {
  left: 0px;
  width: 115px;
  box-shadow: inset -2px 0 0 0 black, inset 0 -2px 0 0 black, inset 0 2px 0 0 black;
  border: 0;
}
.li:last-of-type:before, .li:last-of-type:after {
  left: -15px;
  border-right: 0;
}
.li:last-of-type:hover {background: #C0EBA4;}

 /* Wizard control start */
.MuiStep-horizontal:first-of-type {
  left: -11px; 
  border-left: 2px solid transparent;
  background: #EFEFEF;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.MuiStep-horizontal:last-of-type {
  left: 0px; 
  box-shadow: inset -2px 0 0 0 transparent, inset 0 -2px 0 0 transparent, inset 0 2px 0 0 transparent;
  border: 0;
  background: #EFEFEF;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.MuiStep-horizontal {
  height: 50px;  
  background: transparent;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  line-height: 50px;
  font-family: sans-serif;
  font-size: 15px;
}
.MuiStep-horizontal:first-of-type:before, .MuiStep-horizontal:first-of-type:after {
  left: -1px;
  width: 100%;
  border-left: 0;
}
.MuiStep-horizontal:before {
  border-top: 2px solid transparent;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: skewX(30deg);
  -moz-transform: skewX(30deg);
  -ms-transform: skewX(30deg);
  transform: skewX(30deg);
  background: #EFEFEF;
  top: 0;
  box-shadow: inset 0 8px 0 8px #EFEFEF, inset -6px 8px 0 8px #EFEFEF;
}
.MuiStep-horizontal:before, .MuiStep-horizontal:after {
  content: '';
  left: -15px;
  position: absolute;
  height: 24px;
  width: 100%;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.MuiStep-horizontal:after {
  border-bottom: 2px solid transparent;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  transform: skewX(-30deg);
  bottom: 0;
  box-shadow: inset 0 -8px 0 8px #EFEFEF, inset -6px -8px 0 8px #EFEFEF;
  background: #EFEFEF;
}
.MuiStep-horizontal:last-of-type:before, .MuiStep-horizontal:last-of-type:after {
  left: -14px;
  border-right: 0;
  width: 98%;
}
.activeStep:before, .activeStep:after, .activeStep.MuiStep-horizontal:first-of-type, .activeStep.MuiStep-horizontal:last-of-type {
  background: #FFC051;
}
.activeStep:before {
  box-shadow: inset 0 8px 0 8px #ffc051, inset -6px 8px 0 8px #ffc051;
}
.activeStep:after {
  box-shadow: inset 0 -8px 0 8px #ffc051, inset -6px -8px 0 8px #ffc051;
}
.activeStep .Mui-active {
  color: #fff !important;
  font-weight: 600 !important;
}

.activeStep span {
  color: #fff !important;
  font-weight: 600 !important;
}

.completeStep:before, .completeStep:after, .completeStep.MuiStep-horizontal:first-of-type, .completeStep.MuiStep-horizontal:last-of-type {
  background: #0BC568;
}

.completeStep:before {
  box-shadow: inset 0 8px 0 8px #0BC568, inset -6px 8px 0 8px #0BC568;
}
.completeStep:after {
  box-shadow: inset 0 -8px 0 8px #0BC568, inset -6px -8px 0 8px #0BC568;
}

.completeStep span {
  color: #fff !important;
  font-weight: 600 !important;
}

 /* Wizard control end */

/* Wizard control end */

.fit-content {
  width: fit-content;
}
.group-label-info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.info-icon {
  font-size: 16px !important; 
}

.p-5 {
  padding: 15px !important;
}

.fs {
  font-size: 13px;
}

div[aria-labelledby="valueRange"] svg.MuiSvgIcon-fontSizeMedium.info-icon {
  top: 11px;
  left: -18px;
}

/* Multiline */

.MuiFormControl-fullWidth .MuiInputBase-multiline {
  height: auto !important;
}

.MuiInputBase-multiline textarea {
  font-size: 0.8rem;
}
 
@media screen and (max-width: 1024px) {
  .MuiTabs-flexContainer a.MuiTab-root {
    width: 50%;
  }

  /* Note scroll */
  
  .note-scroll {
    width: 10px;
    overflow: hidden
  }
  
  .mr-15 {
    margin-right: 0px !important;
  }

  .animate {
    /* -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%); */
    -moz-animation: scroll-left 15s linear infinite;
    -webkit-animation: scroll-left 15s linear infinite;
    animation: scroll-left 15s linear infinite;
    white-space: nowrap; 
    padding-left: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @-moz-keyframes scroll-left {
      0% {
          -moz-transform: translateX(0%);
      }

      100% {
          -moz-transform: translateX(-100%);
      }
  }

  @-webkit-keyframes scroll-left {
      0% {
          -webkit-transform: translateX(0%);
          /* //left: 100%; */
      }

      100% {
          -webkit-transform: translateX(-100%);
          /* //left:0; */
      }
  }

  @keyframes scroll-left {
      0% {
          -moz-transform: translateX(0%);
          -webkit-transform: translateX(0%);
          transform: translateX(0);
      }

      100% {
          -moz-transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
      }
  }

}

@media screen and (max-width: 425px) {
  .MuiTabs-flexContainer a.MuiTab-root {
    width: 100%;
  }

  .question-group .question-row {
    left: 0 !important;
  }
}
 