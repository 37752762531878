.bg-layout.access { 
    height: calc(100vh - 70px);
    display: flex; 
}

.bg-layout.access .MuiSvgIcon-root{
    font-size: 52px;
    margin-top: 25vh;
}

.bg-layout.access h1 {
    margin: 10px 0 0;
}

.bg-layout.access p {
    font-size: 18px;
    margin: 10px 0 0;
}
 
.bg-layout.access .container {
    width: 30vw;
    margin: 0 auto;
}