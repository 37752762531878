.pro-details {
    padding: 0 !important;
}

.more-details {
    padding-bottom: 13px !important;
}

.description {
    justify-content: flex-start !important;
    align-items: center;
}

.badge {
    background-color: #F5F5F5;
    border: 1px solid #D4D4D4;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px; 
}