/* Border Removed */
.filter-search fieldset.MuiOutlinedInput-notchedOutline, .filter-search .Mui-focused fieldset.MuiOutlinedInput-notchedOutline, .filter-grp fieldset.MuiOutlinedInput-notchedOutline, .filter-grp .Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
    border-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
} 
.MuiInputBase-formControl:hover {
    border: 0px solid #fff;
}

/* `-type */
.account-user {
    flex-direction: column;
    padding: 0;
    height: 100%;
}

.account-user > button {
    height: 100%;
    color: #2F3D6D;
    padding: 0 20px;
    text-transform: capitalize;
    justify-content: space-between;
}

.submenu ul li {
    font-size: 13px;
    font-weight: 500;
    color: #2F3D6D;
    text-transform: capitalize; 
}

.new-user-control .MuiInputBase-formControl {
    padding: 0 !important;
}

.dropdown-list > .MuiInputBase-root > input {
    padding: 0 20px !important;
    font-weight: 500;
    color: #353d76;
}

.icon-style {
    color: #2F3D6D;
    font-size: 1.2rem;
}
 
/* Form Divider */
.border-right, .search-border {
    border-right: 1px solid #e9e9e9;
}

/* Form Font*/
.MuiInputBase-root input.MuiInputBase-input, .MuiAutocomplete-popper div{
    font-size: 0.8rem;
}


/* Filter Search */
.filter .MuiFormGroup-root {
    flex-direction: row;
}

.MuiGrid-root .filter-search { 
    flex-direction: row;
    height: 70px;
    align-items: center;
    flex-grow: 1;
}
.MuiFormControl-root.filter-search-control {
    width: calc(100% - 1rem);
    padding: 0.5rem;
}

/* Filter Group */
.MuiGrid-root .filter-grp {
    flex-direction: row;
    height: 70px;
    align-items: center;
    justify-content: flex-end;
}
.MuiFormControl-root.filter-control.custom-user {
    width: 100%; 
    padding: 5px 0;
}

.admin-contact-group {
    display: flex;
    flex-direction: row;
    padding: 6px 0px;
    max-width: calc(100% - 10px);
    justify-content: space-between;
}

.icon-group {
    position: relative;
    top: 11px;
    left: -8px;
}

/* Profile */
.MuiBox-root .profile-btn { 
    width: 100%;
    height: 70px; 
    padding: 1.5rem;
}

.MuiBox-root .profile-btn span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
}

.blue-text-color {
    color: blue;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 4px;
}


@media screen and (max-width: 765px) {
    .account-user > button {
        justify-content: space-around;
        overflow: hidden;
        text-wrap: nowrap;
    }
}