div[role="presentation"] .MuiBackdrop-root.MuiModal-backdrop {
    /* background-color: rgb(0 0 0 / 4%); */
}

.MuiModal-backdrop+div[data-testid="sentinelStart"]+.MuiDialog-container .MuiPaper-elevation {
    box-shadow: none;
}

.MuiDialogContent-root.MuiDialogContent-dividers.Gallery {
    padding: 14px;
    height: 80vh;
    overflow: hidden;
}

.pre-btn {
    color: #2F3D6D;
    background: #F5F5F5;
    border: 1px solid #D4D4D4;
    padding: 7px 15px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    left: 25px;
    top: 53%;
    z-index: 9;
    line-height: 5px;
    cursor: pointer;
}

.next-btn {
    color: #2F3D6D;
    background: #F5F5F5;
    border: 1px solid #D4D4D4;
    padding: 7px 15px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    right: 45px;
    top: 53%;
    z-index: 9;
    line-height: 5px;
    cursor: pointer;
}

li.slide div {
    /* height: calc(100vh - 193px); */
}

li.slide div img {
    /* height: 100%; */
    border: 1px solid #ddd !important;
}

.MuiDialog-root {
    position: absolute !important;
}

.control-dots {
    display: none;
}

.legend {
    bottom: inherit !important;
    left: inherit !important;
    top: 74px;
    right: 45px;
    width: auto !important;
    border-radius: 30px !important;
    opacity: 1 !important;
    transition: opacity .35s ease-in-out;
    color: #2F3D6D !important;
    background: #F5F5F5 !important;
    border: 1px solid #D4D4D4;
    padding: 7px 15px !important;
    font-weight: 500;
    font-size: 12px !important;
    margin-left: 7px !important;
    position: absolute;
    z-index: 9;
}

.carousel .carousel-status {
    /* top: auto !important;
    bottom: 0 !important; */
    right: 50% !important;
    text-shadow: none !important;
    color: #2F3D6D !important;
    background: #F5F5F5 !important;
    border: 1px solid #D4D4D4;
    padding: 7px 15px !important;
    border-radius: 30px !important;
    font-weight: 500;
    font-size: 12px !important;
}

.carousel.carousel-slider .control-arrow {
    background: rgba(0, 0, 0, 0.2) !important;
}

.modal-header {
    line-height: 36px;
    color: #2F3D6D;
    font-weight: 700 !important;
    font-size: 18px !important;
    font-family: 'segoe UI', sans-serif;
    font-style: normal;
    padding-left: 25px;
    text-transform: capitalize;
    cursor: all-scroll;
}

.exceptionNo {
    color: #2F3D6D;
    background: #F5F5F5;
    border: 1px solid #D4D4D4;
    padding: 7px 15px;
    border-radius: 25px;
    font-weight: 500;
    font-size: 12px;
    margin-left: 7px;
    position: relative;
    top: -1px;
}

.button-controls {
    position: absolute;
    bottom: 30px;
    right: 48px;
    display: flex;
    flex-direction: row;
    z-index: 99;
}

.image-container {
    /* max-width: 100%;
    max-height: 100%; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.draggable-image {
    /* cursor: grab; */
    position: relative;
    width: 100%;
    height: 100%; 
}

button.page-control-btn,
button.page-control-btn:hover {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    border-radius: 0px;
    border: 1px solid #d4d4d4;
    color: #0BADC5;
}


.Mui-disabled.page-control-btn {
    background-color: #e9e9e9 !important;
}

button.page-control-btn:nth-child(2),
button.page-control-btn:nth-child(3),
button.page-control-btn:nth-child(4) {
    border-left: 0;
}

.highlight .draggable-image {
    top: 0 !important;
    left: 0 !important;
}

.carousel-root {
    overflow: hidden;
}

.carousel .slide { 
    overflow-y: scroll;
    height: 80vh;
}

.image-container.highlight .draggable-image {
    cursor: auto !important;
}

.image-container .draggable-image {
    cursor: drag !important;
}