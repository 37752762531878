.reported-terminal .MuiInputBase-input {
    position: relative;
    top: -6px;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}


.full-width {
    width: 100% !important;
}

.l-5 {
    left: 5px !important;
}

.height-40 {
    height: 40px !important;
}