.col-md-4 {
    width: 33.33%;
}

.col-md-12 {
    width: 100%;
}

.col-md-6 {
    width: 50%;
}

.mt-1 {
    margin-top: 10px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.custom-group label.MuiFormLabel-root {
    font-size: 0.85rem !important;
    font-weight: 400 !important;
    left: 0 !important;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: initial !important;
}

.all-short-entry {
    flex-direction: column;
}

.checkbox-shoartage {
    display: inline-flex !important;
    margin: 0 !important;
    left: -3px !important;
    top: -1px;
}

.checkbox-shoartage span {
    padding: 5px 0;
}

.flex-start {
    justify-content: flex-start !important;
}

.header-text {
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
}

.custom-container-pos {
    margin-left: 0 !important;
    width: 100% !important;
}

.field-error {
    color: #d32f2f;
    font-size: 0.8rem;
    text-transform: capitalize;
}

.date-label-margin {
    margin-top: 0;
    margin-bottom: 9px;
}

.justify-content {
    justify-content: flex-start !important;
    margin-bottom: 10px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.justify-content-left {
    justify-content: left !important;
}