.image-wrapper {
    height: calc(100vh - 140px);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 765px) {
   .image-wrapper img {
    width: 80%;
   }
}