/* Grid Layout */
.grid-padding  {
    height: calc(100vh - 175px);
    width: calc(100vw - 32px);
    padding: 1rem;
}

/* Datagrid style */
.MuiDataGrid-columnHeaderTitle {
    font-weight: 400 !important;
    font-size: 12px;
    color: #828598;
    text-transform: capitalize;
    position: relative;
    top: 1px;
}
.MuiDataGrid-columnSeparator svg.MuiSvgIcon-root {
    color: transparent;
}
.MuiDataGrid-columnHeaders {
    background: #fff;
}
.MuiDataGrid-virtualScroller .MuiDataGrid-row.even {
    background-color: #FBFBFB !important;
}
.MuiDataGrid-row.even .MuiDataGrid-cell, .MuiDataGrid-row.odd .MuiDataGrid-cell { 
    border-bottom: 1px solid #EEEEEE;
    font-size: 12px;
} 
.MuiDataGrid-columnHeadersInner div[role='row'], .MuiDataGrid-virtualScroller {
    background: #fff;
}
.MuiDataGrid-cell span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.MuiDataGrid-cell[data-field="viewDescription"] svg {
    position: relative !important;
    top: 2px;
    cursor: pointer;
}
 
/* Datagrid Pagination buttons */
.table-export {
    position: relative;
    top: 2px;
}
.button-set-pagination {
    position: absolute;
    bottom: 90px;
    right: 450px;
    height: 52px;
    border-right: 1px solid #ccc;
} 
.assign{
    font-size: 13px !important;
    background-color: #0BADC5 !important;
}
.entry {
    font-size: 13px !important;
    background-color: #0BC568 !important;
}
.disable {
    cursor: not-allowed !important; 
    background-color: grey !important; 
}

/* Tooltip styling */
.MuiTooltip-tooltip {
    font-weight: 300 !important;
    line-height: 16px;
    text-transform: capitalize;
}

/* .overage-layout .button-set-pagination {    
    bottom: 88px;
} */

.overage-layout .button-set-pagination .table-export {
    top: -1px;
}

@media screen and (max-width: 768px) {
    /* Grid Layout */
    .grid-padding {
        height: calc(100vh - 240px);
    }

    /* Datagrid Pagination buttons */
    .button-set-pagination { 
        left: 40px;
        bottom: 123px;
        border-right: 0;
    }

    .overage-layout .button-set-pagination .table-export {
        top: 32px;
    }
}