.MuiDataGrid-columnHeader[data-field="viewDescription"] .MuiDataGrid-columnHeaderTitle {
    color: transparent;
}
.filter-criteria{
    padding: 10px 0px 0px 17px;
    background: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 500 !important;
    font-size: 11px;
    color: #2F3D6D;
    text-transform: uppercase;
}

.filter-criteria span {
    color: #828598;
}

.overage-layout .grid-padding{ 
    height: calc(100vh - 200px); 
    padding-top: 0.7rem;
}

.MuiDataGrid-cell[data-field="photos"] {
    cursor: pointer;
}