.filter-Header {
    width: 100%;
    position: relative;
}

/* Border Removed */
.filter-search fieldset.MuiOutlinedInput-notchedOutline, .filter-search .Mui-focused fieldset.MuiOutlinedInput-notchedOutline, .filter-grp fieldset.MuiOutlinedInput-notchedOutline, .filter-grp .Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
    border-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
} 
.MuiInputBase-formControl:hover {
    border: 0px solid #fff;
}

.terminal .MuiOutlinedInput-root.MuiInputBase-sizeSmall { 
    height: 65px !important;
}

/* Filter Search */
.filter .MuiFormGroup-root {
    flex-direction: row;
}

.MuiGrid-root .filter-search { 
    flex-direction: row;
    height: 70px;
    align-items: center;
    flex-grow: 1;
    border-right: 1px solid #e9e9e9;
}
.MuiFormControl-root.filter-search-control {
    width: calc(100% - 1rem);
    padding: 0.5rem;
}

/* Filter Group */
.MuiGrid-root .filter-grp {
    flex-direction: row;
    height: 70px;
    align-items: center;
}
.MuiFormControl-root.filter-control {
    width: calc(50% - 1rem);
    padding: 0.5rem;
}