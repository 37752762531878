.bg-layout {
    background: #f1f1f1;
    height: calc(100vh - 155px);
    padding: 15px 15px 0;
}

.bg-layout>.header-section {
    height: 70px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bg-layout>.header-section>.back-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bg-layout>.header-section>.back-section>.back {
    width: 28px;
    height: 28px;
    background-color: #232C4D;
    border: 0px solid #e3e3e3;
    border-radius: 25px;
    margin-left: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #a4acb3;
    cursor: pointer;
}

.page-hader-content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.page-header {
    color: #2F3D6D;
    font-weight: 700;
    font-size: 18px;
    font-family: 'segoe UI', sans-serif;
}

.page-subtitle {
    color: #A4ACB3;
    font-size: 11px;
}

.edit-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MuiButton-sizeMedium.MuiButton-containedSizeMedium.assign {
    box-shadow: none;
    font-weight: 400;
    border-radius: 20px;
    margin: 8px 5px;
    text-transform: capitalize;
    min-width: 95px;
    margin-right: 20px;
    margin-left: 20px;
}

.entry {
    border-radius: 25px !important;
    box-shadow: none !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.mr-20 {
margin-right: 20px !important;
}

/* Content area */
.content-area.osd-description {
    background-color: #FBFBFB;
    border: 1px solid #eee;
    height: calc(100vh - 225px);
    overflow-y: auto;
    padding: 0;
}

.another-class-name > .content-area.osd-description {
    height: auto !important;
    background-color: transparent !important;
}

.MuiAccordion-root {
    /* margin-bottom: 15px; */
}

.MuiAccordionSummary-root.header {
    background-color: #F5F7FB;
    min-height: 50px !important;
    height: 50px;
    padding-left: 0;
}

.MuiAccordion-root::before {
    border-top: 1px solid #f5f7fb;
}

.content-area .grid-padding {
    height: auto;
    width: auto;
    padding: 0;
}

.content-area .grid-padding .MuiDataGrid-root {
    border-color: transparent;
}

.content-area .button-set-pagination {
    display: none;
}

.MuiButtonBase-root.des-header {
    background-color: #2F3D6D;
    min-height: 55px !important;
    height: 55px !important;
}

.table-accordion.MuiPaper-root.MuiPaper-elevation.Mui-expanded {
    margin: 0;
    box-shadow: none;
}

.accordion-header {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #fff;
}

.MuiAccordionDetails-root.details-accordion {
    padding: 0 ;
}

/* boxes */

.MuiPaper-root .background-box { 
    padding: 10px 5px !important;
}

.more-details {
    padding: 0px 8px 0px !important;
}

.more-details .MuiGrid-root{
    margin: 0;
    width: 100%;
}

.grey-box {  
    padding: 5px 15px 10px;
    background: #FBFBFB;
    border: 1px solid #EBEBEB;
}

.content-box { 
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-box .label {
    color: #828598;
    font-size: 12px; 
    text-transform: uppercase;
}

.content-box .value {
    color: #2F3D6D;
    font-size: 14px;
    font-weight: 400;
}

.MuiGrid-root.MuiGrid-item.pb-0 {
    padding-bottom: 0 !important;
}
 
.MuiAccordionSummary-expandIconWrapper {
    width: 28px;
    height: 28px;
    background: #232C4D;
    border-radius: 25px;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiGrid-grid-xs-9 .content-box + .content-box {
    min-height: 40px;
    height: auto;
}

.remarks {
    height: calc(100% - 17px);
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    margin-left: 16px;
}
.consignee-border {
    padding-left: 25px;
}

.consignee-border::before {
    content: '';
    border-left: 1px solid #404f83;
    padding-top: 18px;
    padding-bottom: 18px;
    position: relative;
    left: -12px;
}


 /* 5116 Fix  */
.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiInputBase-root div[role="combobox"] {
    min-width: fit-content;
}
.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiInputBase-root div[role="combobox"]:focus {
    background-color: transparent !important;
}