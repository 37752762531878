/* Card Background */
.card-bg {
    background: url('/public/card-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    color: #fff;
    display: flex;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.card-bg:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-bg:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(255, 255, 255, 0.1));
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.320, 1);
    z-index: 1;
}

.card-bg:hover:before {
    transform: translateX(-100%);
}

.card-bg:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(255, 255, 255, 0.1));
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.320, 1);
    z-index: 1;
}

.card-bg:hover:after {
    transform: translateX(100%);
}

.card-bg .padding {
    width: 100%;
    padding: 15px;
}

/* Card Content */
.card-header {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    height: 35px;
}

.content-card {
    height: 145px;
    position: relative;
}

.content-card h2.count {
    font-size: 30px;
    color: #fff;
    position: absolute;
    bottom: 0;
    margin: 0;
    line-height: 23px;
}

.content-card h2.count-2 {
    font-size: 30px;
    color: #fff;
    position: absolute;
    bottom: 70px;
    margin: 0;
    line-height: 23px;
}

.content-card p {
    font-size: 14px;
    color: #fff;
    margin: 0 0 5px;
    font-weight: 100;
    font-style: italic;
}

@media screen and (min-width: 1920px) {
    /* Card Content */
    .content-card {
        height: 200px;
        position: relative;
    }
}

@media screen and (min-width: 2560px) {
    /* Card Content */
    .content-card {
        height: 290px;
        position: relative;
    }
}

@media screen and (max-width: 1366px) {
    .card-header { 
        font-size: 14px;
        font-weight: 500;
    }

    .content-card h2.count {
        font-size: 24px;
         
    }
    
    .content-card h2.count-2 {
        font-size: 24px;
        bottom: 55px;
    }

    .content-card p {  
        margin: 0 0; 
    }
}