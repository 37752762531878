.bg-layout {
  background: #f1f1f1;
  height: calc(100vh - 155px);
  padding: 15px 15px 0;
}

.bg-layout > .header-section {
  height: 70px;
  background: #F5F7FB;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bg-layout > .header-section > .back-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bg-layout > .header-section > .back-section > .back {
  width: 28px;
  height: 28px;
  background-color: #232C4D;
  border: 0px solid #e3e3e3;
  border-radius: 25px;
  margin-left: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #a4acb3;
  cursor: pointer;
}

.bg-layout > .header-section > .back-section > .back > a > svg {
  color: #fff;
  font-size: 1.5rem;
  position: relative;
  left: -1px;
  top: 2px;
}

.page-hader-content {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.page-header {
  color: #2f3d6d;
  font-weight: 700;
  font-size: 18px;
  font-family: "segoe UI", sans-serif;
}

.page-subtitle {
  color: #a4acb3;
  font-size: 11px;
}

.edit-section {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}

.MuiButton-sizeMedium.MuiButton-containedSizeMedium.assign {
  box-shadow: none;
  font-weight: 400;
  border-radius: 20px;
  margin: 8px 5px;
  text-transform: capitalize;
  min-width: 95px;
  margin-right: 20px;
}

/* Content area */
.content-area {
  background-color: #fbfbfb;
  border: 1px solid #eee;
  height: calc(100vh - 245px);
  padding: 20px 20px 0;
  overflow-y: scroll;
}

.MuiAccordion-root {
  margin-bottom: 15px;
}

.MuiAccordionSummary-root.header {
  background-color: #f5f7fb;
  min-height: 50px !important;
  height: 50px;
  padding-left: 0;
}

.MuiAccordion-root::before {
  border-top: 1px solid #f5f7fb;
}

.queue-check {
  background-color: #ffc051;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.queue-check-gc {
  background-color: #0bc568 !important;
}

.MuiAccordion-root.accordion {
  border-radius: 0;
  box-shadow: none;
}

.overage-entry .accordion-header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: initial;
  text-wrap: nowrap;
}

.overage-entry .MuiAccordionSummary-expandIconWrapper {
  background-color: transparent !important;
  color: #232c4d !important;
}

.MuiTypography-root.header-notice {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  min-width: calc(100vw - 350px);
  justify-content: center;
}

.MuiTypography-root.header-notice-2 {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  min-width: calc(100vw - 400px);
  justify-content: center;
}

.accordion-container {
  padding: 16px !important;
}

sup {
  color: #d52027;
}

.float-div.textarea {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
}

.textarea .MuiInputBase-colorPrimary, .textarea-radio-group{
  height: auto !important;
}

.form-row textarea {
  font-size: 13px;
}

.radio-group .MuiFormGroup-root {
  position: relative;
  left: 14px;
  /* height: 40px; */
}

.radio-group label.MuiFormLabel-root,
.checkbox-group label.MuiFormLabel-root {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  left: 0 !important;
  margin-top: 10px;
  color: #000000e8;
  line-height: initial !important;
  width: fit-content;
}

.radio-group label.MuiFormLabel-root.Mui-focused,
.checkbox-group label.MuiFormLabel-root.Mui-focused {
  color: initial !important;
  font-weight: 400 !important;
}

.radio-group .MuiFormGroup-root > .MuiFormControlLabel-root {
  margin-left: -5px;
  margin-top: 5px;
}

.radio-group
  .MuiFormGroup-root
  > .MuiFormControlLabel-root
  span.MuiButtonBase-root
  input
  + span
  > svg.MuiSvgIcon-root,
.checkbox-group
  .MuiFormGroup-root
  > .MuiFormControlLabel-root
  span.MuiButtonBase-root
  input
  + svg.MuiSvgIcon-root {
  font-size: 1.4rem;
}

.radio-group
  .MuiFormGroup-root
  > .MuiFormControlLabel-root
  span.MuiTypography-root {
  font-size: 0.8rem;
  font-weight: 500;
}

.MuiRadio-colorPrimary svg.MuiSvgIcon-root {
  font-size: 1.4rem;
}

.MuiGrid-root .MuiFormGroup-root .pull-right-label span.MuiButtonBase-root {
  margin-left: 5px;
}

.checkbox-group .MuiGrid-root.MuiGrid-container {
  /* margin-left: 0; */
  margin-top: -5px;
  margin-bottom: 20px;
}

.checkbox-group .MuiGrid-root.MuiGrid-container label.MuiFormControlLabel-root {
  left: 0 !important;
  width: calc(40% - 16px);
  top: 1px;
  margin-top: 0;
}

.checkbox-group
  .MuiGrid-root.MuiGrid-container
  label.MuiFormControlLabel-root
  span.MuiTypography-root {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
}

.overage-entry .min-width {
  width: 100%;
}

.overage-entry .form-row {
  flex-direction: row;
}

.justify-content {
  justify-content: flex-end;
  flex: 1;
}

.overage-entry svg.MuiSvgIcon-root path {
  stroke: white;
}
.disabled {
  max-width: 55px;
}
.disabled fieldset[aria-hidden="true"] {
  border-right: 0;
}

input[value="BL#"],
input[value="PO#"],
input[value="SN#"],
input[value="$"] {
  font-weight: 500 !important;
  -webkit-text-fill-color: rgb(52 52 52) !important;
  text-align: center;
}

.value-range {
  width: 100%;
}

.float-div {
  display: flex;
  align-items: flex-end;
}

.accordion-content-header {
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 16px;
}

.info-icon {
  color: #0badc5;
  position: relative;
  top: 2px;
  font-size: 0.8rem !important;
  cursor: pointer;
}

.ml {
  margin-left: 5px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.btn-group-submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

em {
  font-size: 0.8rem !important;
  color: #A4ACB3;
  font-weight: 400;
  font-style: normal;
}

.MuiFormControl-root .MuiInputBase-root.Mui-disabled {
  background: #f5f7fb;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.question-group {
  min-width: 100% !important;
}

.question-group .question-row {
  flex-grow: 1;
}

.question-group .question-row .MuiFormControl-root {
  min-width: calc(100% - 25px);
}

.form-validation {
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1;
}

.p10 {
  padding-top: 10px !important;
}

.checkbox-group-validation,
.radio-group-validation {
  margin-left: 15px;
}

.MuiRadio-colorPrimary {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  height: 30px;
  width: 30px;
}

.form-validation .form-error {
  position: absolute;
  bottom: 0px;
}

.align-items {
  align-items: center;
}

.form-content-start {
  align-items: flex-start;
}

.shift-left {
  left: 0px !important;
}

.round-info {
  position: absolute;
  right: 10px;
  top: 13px;
}

.question-group .MuiGrid-container .MuiGrid-item {
  margin-top: 4px;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.MuiSnackbar-root.snackbar-error {
  position: relative;
  z-index: 0;
  top: 0 !important;
}

.MuiSnackbar-root.snackbar-error .MuiPaper-root {
  flex-grow: 1;
  background-color: #ffebeb;
  box-shadow: none;
  border: 1px solid #ffb9b9;
  display: flex;
  justify-content: center; 
}

.MuiSnackbar-root.snackbar-error.warning .MuiPaper-root {
  flex-grow: 1;
  background-color: #FFF5E3;
  box-shadow: none;
  border: 1px solid #FFC051;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.content-area .accordion.overage-invistigation {
  margin-bottom: 0;
}

.MuiSnackbar-root.snackbar-error .MuiPaper-root .MuiSnackbarContent-message {
  color: #d52027;
}

.MuiSnackbar-root.snackbar-error.warning .MuiPaper-root .MuiSnackbarContent-message {
  color: #e0a94a;
}

.assign.mr-0 {
  margin-right: 0;
}

.btn-group-submit button {
  min-width: 95px;
}

.custom-autocomplete .MuiAutocomplete-endAdornment {
  top: calc(50% - 18px);
  pointer-events: none;
  transform: initial;
}

.filter .custom-autocomplete .MuiAutocomplete-endAdornment {
  top: calc(50% - 14px);
  display: none;
}

.custom-autocomplete .MuiInputBase-root input#reporting-terminal {
  padding-left: 8px !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}
.badge {
  background-color: #F5F5F5;
  border: 1px solid #D4D4D4;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px; 
}

.print-doc {
  display: none;
}

input[type="file"]{
  margin: 0 0 5px;
}

.click-upload {
    margin: 0 !important;
    height: 38px !important;
    border-radius: 3px !important;
    min-width: 38px !important;
    max-width: 38px !important;
    position: relative !important;
    left: 13px;
}

.click-upload .MuiButton-startIcon {
  margin: 0 !important;
}

.font-weight {
  font-weight: 500; 
}

.font-weight i {
  color: #D52027;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@media print {
  #overageEntry { 
    margin: 15px; 
  }

  .float-div {
    display: block;
  }

  .question-group .question-row {
    left: 0;
  }

  .print-title {
    color: #2f3d6d;
    font-weight: 700;
    font-size: 18px;
    font-family: "segoe UI", sans-serif;
  }

  .print-badge {
    background-color: #F5F5F5;
    border: 1px solid #D4D4D4;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px; 
  }

  .print-doc {
    display: block;
  }
}

@page {
  size: A4;
  margin: 3rem;
}

li.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  flex-wrap: wrap;
  word-wrap: break-word;
  /* width: 300px; */
}

h1,
p {
  font-family: Lato;
}

.hide-me {
  display: none;
}


.hide-me-tab {
  display: none !important;
}

canvas {
  width: 100%;
}

.hide-error {
  display: none;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-bottom: 2px !important;
}