/* Dashboard Container */
.layout .center-container {
    margin: 0 auto;
    justify-content: center;
    /* max-height: 330px; */
}

.layout .card-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: center;
    padding: 32px 16px 16px;
    margin: 0 auto;
    max-width: 65%;
}

/* Dashboard Text */
.dashboard>.MuiGrid-item>h2 {
    text-align: center;
    color: #2F3D6D;
    font-size: 18px;
}

/* Input Search */
.search-input {
    background: #FFFFFF;
    border-radius: 30px;
    width: calc(100% - 130px);
    margin-right: 25px;
}
.dashboard fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #e9e9e9;
    border-radius: 30px;
}

/* Search Button */
.MuiFormGroup-root .search-btn {
    font-weight: 400;
    border-radius: 25px;
    margin: 0;
    text-transform: capitalize;
    min-width: 110px;
    min-height: 40px;
    background-size: 300% 100%;
    /* background-color: #0BADC5; */
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
} 
.MuiFormGroup-root .search-btn:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.search-btn.search-effect {
    background-image: linear-gradient(to right,
            #0BADC5,
            #0d8fa3, 
            #0BADC5); 
}

/* Input search-button Misc */
.dashboard .MuiFormControl-root {
    background: transparent;
    border-radius: 30px;
    margin-right: 20px;
}
.flex-update .MuiInputBase-colorPrimary {
    border-radius: 20px !important;
}
.MuiFormGroup-root.flex-update {
    flex-direction: row;
}

@media screen and (max-width: 1024px) {
    /* Dashboard Container */
    .layout .card-container {
        max-width: 95%;
    }
}

@media screen and (max-width: 820px) {
    /* Dashboard Container */
    .layout .card-container {
        max-width: 85%;
    }
}

@media screen and (max-width: 425px) {
    /* Dashboard Container */
    .layout .card-container {
        max-width: 100%;
    }

    .card-bg{
        max-width: 40% !important;
        flex-basis: 40% !important;
    }

    .button-set-pagination {
        left: 22px !important;
        bottom: 88px !important;
        border-right: 0 !important;
        right: inherit !important;
    }

    .overage-layout .button-set-pagination .table-export {
        top: initial !important;
    }

    .table-export .MuiButton-containedSizeMedium.assign {
        margin-right: 0 !important;
        margin-left: 5px !important;
    }

    .MuiTablePagination-root .MuiTablePagination-actions{
        margin-left: 0 !important;
    }
}