.img-checkbox {
    width: initial;
    height: initial;
    position: absolute !important;
    top: 8px;
    left: 8px;
}

.grid-image {
    align-items: flex-start;
}

.image-legend { 
    position: absolute;
    left: inherit;
    top: 10px;
    right: 10px;
    width: auto;
    border-radius: 30px;
    opacity: 1;
    transition: opacity .35s ease-in-out;
    color: #2F3D6D;
    background: #F5F5F5;
    border: 1px solid #D4D4D4;
    padding: 7px 15px;
    font-weight: 500;
    font-size: 12px;
    margin-left: 7px;
}

.grid-image img {
    overflow: hidden;
}

.margin-grid {
    margin: 0 0 5px;
}

.no-image {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
}